const state = {
  search: {
    data: '',
  },
  tabs: {
    active: 'Home',
    tab: [],
  },
}
const mutations = {
  SET_SEARCH: (state, search) => {
    state.search = {
      ...state.search,
      ...search,
    }
  },
  SET_TABS: (state, tabs) => {
    state.tabs = {
      ...state.tabs,
      ...tabs,
    }
  },
}
const actions = {
  setSearch({ commit }, search) {
    commit('SET_SEARCH', search)
  },
  setTabs({ commit }, tabs) {
    commit('SET_TABS', tabs)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
