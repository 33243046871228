import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}

const Layout = () => import('@/layouts/Layout.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home.vue'),
      },
      {
        path: 'product-detail',
        name: 'ProductDetail',
        component: () => import('@/views/product-detail.vue'),
      },
      {
        path: 'about-us',
        name: 'AboutUs',
        component: () => import('@/views/about-us.vue'),
      },
      {
        path: 'user-agreement',
        name: 'UserAgreement',
        component: () => import('@/views/user-agreement.vue'),
      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/privacy-policy.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
