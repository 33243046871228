import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'normalize.css'
import './styles/element-variables.scss'

import {
  Backtop,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Carousel,
  CarouselItem,
  Checkbox,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Icon,
  Input,
  Message,
  Pagination,
  RadioButton,
  RadioGroup,
  Tabs,
  TabPane,
} from 'element-ui'

Vue.config.productionTip = false

Vue.use(Backtop)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Button)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Checkbox)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Pagination)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Tabs)
Vue.use(TabPane)

Vue.prototype.$message = Message

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
